import * as React from 'react';
import cn from 'classnames';
import HtmlContent from 'styleguide/components/HtmlContent/HtmlContent';
import Collapse from 'styleguide/components/Collapse/Collapse';
import { clickFAQ, clickEmailUs } from 'api/gtm';
import AppContext from 'contexts/AppContext/AppContext';
import { useLocation } from 'react-router-dom';
import { isProductPage } from 'libs/utils/pageUtils';

interface Props {
  questions: Array<{ question: string; answer: string }>;
  className?: string;
  expanded?: boolean;
}

const FAQ = ({ questions, className, expanded }: Props) => {
  const appContext = React.useContext(AppContext);
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isProductPage(location.pathname, appContext.store.cmsPages)) {
      return;
    }
    const target = e.target as HTMLElement;
    if (
      (target.tagName === 'A' && target.textContent?.toLowerCase() === 'email us') ||
      target.textContent?.toLowerCase() === 'contact us'
    ) {
      clickEmailUs({ location: 'pdp_faq' });
    }
  };

  return (
    <div className={cn('w-full', className)}>
      <Collapse>
        {questions.map(({ question, answer }, index) => (
          <div className="mb-6 last:mb-0 max-w-4xl" key={index}>
            <Collapse.Item
              title={<h2 className="my-0 text-lg font-hvBold">{question}</h2>}
              onToggle={() => clickFAQ({ question })}
              body={<HtmlContent content={answer} className="prose max-w-none" onClick={handleClick} />}
              key={index}
              isWide
              withPadding
              classNameBody="overflow-visible"
              initiallyExpanded={expanded}
            />
          </div>
        ))}
      </Collapse>
    </div>
  );
};

export default FAQ;
